<template>
  <div class="recruitmentMattersBox">
    <div class="searchBox">
      <div class="searchLeft">
        <ul>
          <li>
            <p class="searchTitle">招聘事项：</p>
            <el-input v-model="title" placeholder="请输入招聘事项"></el-input>
          </li>
          <li>
            <el-button class="searchBtn" type="warning" @click="saerchFun">查询</el-button>
          </li>
        </ul>
      </div>
    </div>

    <div class="recruitmentMattersContent">
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
          <el-table-column align="center" prop="title" label="招聘事项" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="time" label="报名时间" width="280"></el-table-column>
          <el-table-column align="center" prop="planCount" label="招聘人数" width="80"></el-table-column>
          <el-table-column align="center" prop="applyCount" label="报名人数" width="80"></el-table-column>
          <el-table-column align="center" prop="passCount" label="通过人数" width="80"></el-table-column>
          <el-table-column align="center" prop="perfectCount" label="待完善材料" width="100"></el-table-column>
          <el-table-column align="center" label="操作" width="60">
            <template slot-scope="scope">
              <div class="signUpFlex">
                <p class="signUpColor" @click="seeFun(scope.row)">详情</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-size="10"
          :current-page="page"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情 -->
    <registrationDetails v-if="dialogVisible" @numberDetalsFun="numberDetalsFun" :registrationId="registrationId" v-model="dialogVisible" />

    <!-- 查看具体人 -->
    <numberDetails @seePersonalFun="seePersonalFun" :applyState="applyState" :registrationId="registrationId" :numberId="numberId" v-if="numberVisible" v-model="numberVisible" />

    <!-- 个人详情 -->
    <examineDetails v-if="detailsVisible" :examineId="examineId" v-model="detailsVisible" />
  </div>
</template>
<script>
import registrationDetails from '@/views/registrationStatistics/registrationDetails.vue'
import numberDetails from '@/views/registrationStatistics/numberDetails.vue'
import examineDetails from '@/components/examineDetails.vue'
import {applyStatisticsIndex} from '@/api/registrationStatistics/registrationStatistics'
export default {
  components:{
    registrationDetails,
    numberDetails,
    examineDetails
  },
  data() {
    return {
      total:0,
      page:1,
      title:'',
      dialogVisible:false,
      numberVisible:false,
      detailsVisible:false,
      registrationId:'',
      tableData:[],
      numberId:'',//岗位id
      examineId:'',//详情信息id
      applyState:''
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    saerchFun(){
      this.page = 1;
      this.getData()
    },
    //获取列表信息
    getData(){
      let data = {
        page:this.page,
        limit:10,
        title:this.title
      }
      applyStatisticsIndex(data).then(res=>{
        if(res.status == 200){
          if(res.data.rows&&res.data.rows.length){
            this.tableData = res.data.rows.map(item=>{
              if(item.beginTime&&item.endTime){
                let bIndex = item.beginTime.lastIndexOf(':');
                let bigin = item.beginTime.substring(0,bIndex).replace(/-/g,'.');
                let eIndex = item.endTime.lastIndexOf(':');
                let end = item.endTime.substring(0,eIndex).replace(/-/g,'.');
                item.time = bigin+' ~ '+end
              }
              return item
            });
          }else{
            this.tableData = []
          }
          this.total = res.data.total;
        }
      })
    },
    // 查看
    seeFun(row){
      this.registrationId = row.id;
      this.dialogVisible = true;
    },
    // 查看个人详情
    seePersonalFun(examineId){
      this.examineId = examineId;
      // this.numberVisible = false;
      this.detailsVisible = true;
    },
    //点击个数
    numberDetalsFun(obj){
      this.numberId = obj.id;
      this.applyState = obj.applyState;
      // this.dialogVisible = false;
      this.numberVisible = true;
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    },
  }
}
</script>
<style lang="scss" scoped>
  .recruitmentMattersBox{
    .searchBox{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .searchLeft{
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin-right: 30px;

            .searchTitle{
              width: 120px;
              text-align: right;
            }
            .searchStatus{
              width: 50px;
              text-align: right;
            }
          }
        }
      }

      .searchRight{
        .addBtn{
          background: #bf162a;
          color: #fff;
          border: none;
        }
      }
    }

    .recruitmentMattersContent{
      margin-top: 20px;
      .signUpFlex{
        display: flex;
        justify-content: center;
        .signUpColor{
          color: rgb(17, 166, 234);
          cursor: pointer;
        }
      }
    }
  }
</style>