<template>
  <div class="toExamineDiaBox">
    <el-dialog :visible.sync="dialogVisible" top="80px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="toExamineHeader">

        <div class="searchBox">
          <ul>
            <li>
              <p>单位：</p>
              <el-input size="small" v-model="unit" placeholder="报考单位"></el-input>
            </li>
            <li>
              <p>岗位：</p>
              <el-input size="small" v-model="name" placeholder="报考岗位"></el-input>
            </li>
          </ul>
          <div>
            <el-button size="small" type="warning" @click="getData">查询</el-button>
            <el-button size="small" type="danger" @click="exportFun">导出</el-button>
          </div>
        </div>

        <div class="tableBox">
          <el-table :data="tableData" v-loading="loading" style="width: 100%" max-height="600px">
            <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
            <el-table-column align="center" prop="unit" label="单位" width="120" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="name" label="招聘岗位" width="120"></el-table-column>
            <el-table-column align="center" prop="describea" label="岗位简介" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="type" label="电子邮件" width="120"></el-table-column>
            <el-table-column align="center" prop="gwjb" label="岗位级别" width="120"></el-table-column>
            <el-table-column align="center" prop="positionNum" label="招聘人数" width="90"></el-table-column>
            <el-table-column align="center" prop="applyCount" label="报名人数" width="90">
              <template slot-scope="scope">
                <div>
                  <p @click="numberFun(scope.row)" class="signUpColor">{{scope.row.applyCount}}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="passCount" label="报名通过" width="90">
              <template slot-scope="scope">
                <div>
                  <p @click="numberFun(scope.row,0)" class="signUpColor">{{scope.row.passCount}}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="perfectCount" label="待完善材料" width="100">
              <template slot-scope="scope">
                <div>
                  <p @click="numberFun(scope.row,2)" class="signUpColor">{{scope.row.perfectCount}}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="censusRegisterRequire" label="户籍要求" width="120"></el-table-column>
            <el-table-column align="center" prop="educationBackgroundRequire" label="学历要求" width="140"></el-table-column>
            <el-table-column align="center" prop="diplomaRequire" label="学位要求" width="140"></el-table-column>
            <el-table-column align="center" prop="majorRequire" label="专业要求" width="140"></el-table-column>
            <el-table-column align="center" prop="ageRequire" label="年龄要求" width="100"></el-table-column>
            <el-table-column align="center" prop="politicalLandscapeRequire" label="政治面貌" width="100"></el-table-column>
            <el-table-column align="center" prop="otherRequire" label="其他条件" width="140"></el-table-column>
            <el-table-column align="center" prop="needInterview" label="是否面试" width="140">
              <template slot-scope="scope">
                <div>
                  <span>{{scope.row.needInterview==1?'否':'是'}}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- <div class="pagination">
            <el-pagination
              background
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="10"
              :current-page="page"
              :total="total">
            </el-pagination>
          </div> -->
        </div>
      </div>
      <div class="closeBox">
        <el-button style="width:100px" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {applyStatisticsDetail,applyStatisticsDetailExport} from '@/api/registrationStatistics/registrationStatistics'
import {getToken} from '@/utils/auth'
export default {
  model:{
    prop:'dialogVisible',
    event:'change'
  },
  props:['dialogVisible','registrationId'],
  data() {
    return {
      unit:'',
      name:'',
      page:1,
      total:0,
      tableData:[],
      radioObj:null,
      loading:false
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    //获取列表
    getData(){
      let data = {
        unit:this.unit,
        name:this.name,
        recruitId:this.registrationId
      }
      this.loading = true;
      applyStatisticsDetail(data).then(res=>{
        if(res.status == 200){
          this.tableData = res.data;
          // this.total = res.data.total;
        }
        this.loading = false;
      }).catch(error=>{
        this.loading = false;
      })
    },
    // exportFun
    exportFun(){
      window.open(`${this.$configs}/api/pc/backstage/position/applyStatisticsDetailExport?recruitId=${this.registrationId}&unit=${this.unit}&name=${this.name}&token=${getToken()}`)
    },
    // 状态
    numberFun(row,state){
      this.$emit('numberDetalsFun',{id:row.id,applyState:state})
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .toExamineDiaBox{
    .toExamineHeader{
      padding: 0 30px;
      .toExamineTitle{
        font-size: 18px;
        font-weight: 550;
      }

      .searchBox{
        margin-top: 25px;
        display: flex;
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin: 0 25px 15px 0;

            p{
              width: 80px;
              text-align: right;
            }
          }
        }
      }
    }
    .signUpColor{
      color: rgb(17, 166, 234);
      cursor: pointer;
    }
    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;


      .closeBtn{
        width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
    }
  }
</style>
