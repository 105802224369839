<template>
  <div class="toExamineDiaBox">
    <el-dialog :visible.sync="numberVisible" top="80px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="toExamineHeader">

        <div class="searchBox">
          <ul>
            <li>
              <p>姓名：</p>
              <el-input size="small" v-model="name" placeholder="姓名"></el-input>
            </li>
            <li>
              <p>身份证：</p>
              <el-input size="small" v-model="idCard" placeholder="身份证"></el-input>
            </li>
          </ul>
          <div>
            <el-button size="small" type="warning" @click="searchFun">查询</el-button>
            <el-button size="small" type="danger" @click="exportFun">导出</el-button>
          </div>
        </div>

        <div class="tableBox">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
            <el-table-column align="center" prop="name" label="姓名" width="120"></el-table-column>
            <el-table-column align="center" prop="idCard" label="身份证号" width="170"></el-table-column>
            <el-table-column align="center" prop="sex" label="性别" width="80">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.sex==1">男</span>
                  <span v-if="scope.row.sex==2">女</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="age" label="年龄（岁）" width="90"></el-table-column>
            <el-table-column align="center" prop="phone" label="电话" width="130"></el-table-column>
            <el-table-column align="center" prop="hjyq" label="生源" width="80">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.examineeSource==1">京籍</span>
                  <span v-else>非京籍</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="censusRegister" label="户口所在地" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="hjyq" :formatter="formatterCategory" label="政治面貌" width="160"></el-table-column>
            <el-table-column align="center" prop="unit" label="报考单位" width="130"></el-table-column>
            <el-table-column align="center" prop="positionName" label="报考岗位" width="130"></el-table-column>
            <el-table-column align="center" prop="applyState" :formatter="formatterApplyState" label="报名状态" width="130"></el-table-column>
            <el-table-column align="center" prop="" label="操作" width="100">
              <template slot-scope="scope">
                <div class="operationSty">
                  <p class="signUpColor" style="margin-right: 10px;" @click="seeFun(scope.row)">详情</p>
                  <p class="signUpColor" @click="seeApplication(scope.row)">报名表</p>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              background
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="10"
              :current-page="page"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="closeBox">
        <el-button style="width:100px" @click="handleClose">关闭</el-button>
      </div>


      <el-dialog append-to-body :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import {getApplyAuditDetail} from '@/api/registrationStatistics/registrationStatistics'
import {getDictValueListByDictType} from '@/api/index'
import {getToken} from '@/utils/auth'
export default {
  model:{
    prop:'numberVisible',
    event:'change'
  },
  props:['numberVisible','numberId','registrationId','applyState'],
  data() {
    return {
      name:'',
      idCard:'',
      page:1,
      total:0,
      tableData:[],
      radioObj:null,
      politicalLandscapeList:[],
      dialogVisible:false,
      dialogImageUrl:''
    }
  },
  mounted() {
    this.getDictType()
    this.getData()
  },
  methods:{
    searchFun(){
      this.page = 1;
      this.getData()
    },
    // 获取列表
    getData(){
      let data = {
        recruitId:this.registrationId,
        positionId:this.numberId,
        limit:10,
        page:this.page,
        name:this.name,
        idCard:this.idCard,
        applyState:this.applyState
      }
      getApplyAuditDetail(data).then(res=>{
        if(res.status == 200){
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }
      })
    },
    // 政治面貌
    getDictType(){
      getDictValueListByDictType({dictType:'mct_base_political_landscape'}).then(res=>{
        if(res.status == 200){
          this.politicalLandscapeList = res.data;
        }
      })
    },
    // 导出
    exportFun(){
      let applyStr = '';
      switch (this.applyState) {
        case 0:
          applyStr = `&applyState=${this.applyState}`
          break;
        case 2:
          applyStr = `&applyState=${this.applyState}`
          break;
        default:
          break;
      }
      window.open(`${this.$configs}/api/pc/backstage/examinee/getApplyAuditDetailExport?recruitId=${this.registrationId}${applyStr}&positionId=${this.numberId}&idCard=${this.idCard}&name=${this.name}&token=${getToken()}`)
    },
    //详情
    seeFun(row){
      this.$emit('seePersonalFun',row.id)
    },
    // 表明表
    seeApplication(row){
      this.dialogImageUrl = row.entryForm
      this.dialogVisible = true;
    },
    formatterCategory(row){
      let str = '';
      this.politicalLandscapeList.forEach(item=>{
        if(item.dictDesc==row.politicalLandscape){
          str = item.dictValue
        }
      })
      return str
    },
    //状态
    formatterApplyState(row){
      let str = '';
      if(row.applyState==0){
        str = '已通过'
      }
      if(row.applyState==1){
        str = '未通过'
      }
      if(row.applyState==2){
        str = '待完善材料'
      }
      if(row.applyState==3){
        str = '未提交'
      }
      if(row.applyState==4){
        str = '待审核'
      }
      return str
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .toExamineDiaBox{
    .toExamineHeader{
      padding: 0 30px;
      .toExamineTitle{
        font-size: 18px;
        font-weight: 550;
      }

      .searchBox{
        margin-top: 25px;
        display: flex;
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin: 0 25px 15px 0;

            p{
              width: 80px;
              text-align: right;
            }
          }
        }
      }
    }
    .operationSty{
      display: flex;
      justify-content: center;
      .signUpColor{
        color: rgb(17, 166, 234);
        cursor: pointer;
      }
    }
    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;


      .closeBtn{
        width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
    }
  }
</style>