import axios from '@/utils/axios'

// 报名统计查询
export function applyStatisticsIndex(params){
  return axios({
    url:'/api/pc/backstage/recruit/applyStatisticsIndex',
    method:'get',
    params
  })
}


// 获取岗位
export function applyStatisticsDetail(params){
  return axios({
    url:'/api/pc/backstage/position/applyStatisticsDetail',
    method:'get',
    params
  })
}


// 获取岗位报名人数
export function getApplyAuditDetail(params){
  return axios({
    url:'/api/pc/backstage/examinee/getApplyAuditDetail',
    method:'get',
    params
  })
}


// 导出
export function applyStatisticsDetailExport(params){
  return axios({
    url:'/api/pc/backstage/position/applyStatisticsDetailExport',
    method:'get',
    params
  })
}

