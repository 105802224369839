import { render, staticRenderFns } from "./registrationDetails.vue?vue&type=template&id=20e1c3b5&scoped=true&"
import script from "./registrationDetails.vue?vue&type=script&lang=js&"
export * from "./registrationDetails.vue?vue&type=script&lang=js&"
import style0 from "./registrationDetails.vue?vue&type=style&index=0&id=20e1c3b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e1c3b5",
  null
  
)

export default component.exports